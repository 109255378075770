<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Documents|Orders|CitizenStatements.Resolution' })">
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regnumber`]="{ item }">
                    <v-document-link
                        :id="item.docid"
                        :type="'Chancellery|Documents.Document'"
                        :text="item.regnumber"
                    />
                </template>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{ formatDate(item.regdate, 'DD.MM.YYYY HH:mm:ss') }}</div>
                </template>

                <template v-slot:[`item.resolutiontext`]="{ item }">

                    <div class="elipsis-text-wrap">

                        <v-doc-type-chip
                            type="Resolution"
                            :text="$t('Резолюция')"
                        />

                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.resolutiontext">
                                {{ item.resolutiontext }}
                            </div>
                        </div>
                    </div>

                </template>

                <template v-slot:[`item.authorname`]="{ item }">
                    <v-employee-chip v-if="item.authorname" :id="item.authorid" :name="item.authorname" />
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

export default {
    columnIndex:  {
        'regnumber': 3,
        'regdate': 4,
        'resolutiontext': 5,
        'authorname': 6
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Номер_документа"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Краткое_содержание"), value: 'resolutiontext'},
                { text: i18n.t("Автор"), value: 'authorname', width: '150px' },
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['chancellary/resolutions/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('chancellary/resolutions/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['chancellary/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        },
    },
    methods: {
        resetPage() {
            this.$store.commit('chancellary/resolutions/SET_TABLE_OPTION_PAGE', 1);
        },      
        async getData () {
            if (this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                url: `/api/chancellary/resolutionslist?filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;

            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? true : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;
            return JSON.stringify({ SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
