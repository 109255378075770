<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region Recipients  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.Recipients.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="recipientsItems"
                                    v-model="val.Recipients.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.Recipients.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchRecipients"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    <!--#region DocumentAuthor  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.DocumentAuthor.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="documentAuthorItems"
                                    v-model="val.DocumentAuthor.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.DocumentAuthor.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchDocumentAuthor"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    <!--#region Languages  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.Languages.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="languagesItems"
                                    v-model="val.Languages.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.Languages.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->
                    <!--#region CarrierType  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t(val.CarrierType.label) }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="carrierTypeItems"
                                    v-model="val.CarrierType.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.CarrierType.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->                       

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "ChancellaryNPAExFilter",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),

            recipientsItems: [],
            documentAuthorItems: [],
            languagesItems: [],
            carrierTypeItems: [],
        }
    },
    computed: {

    },
    methods: {
        async initialize() {
            this.recipientsItems = (await this.getContractors()).map( i=> 
                ({
                    id: i[0],
                    Value: i[1],
                })
            );
            this.documentAuthorItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i =>                 
                ({
                    id: i[2],
                    Value: i[1],
                    jobTitle: i[3]
                })
            );
            this.languagesItems = await this.getReference({ id: 1000 });
            this.carrierTypeItems = await this.getReference({ id: 1024 });                 
        },
        ...mapActions('references', ['getContractors', 'getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true, text: 'Загрузка...' });

            this.initialize();

            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);                                
            //this.$notify.success("Фильтр_применен");
            this.resolve();
        },
        async searchRecipients(e) {
            if (e) {
                let selectedIds = this.val.Recipients.value.length ? this.val.Recipients.value.map(i => i.id): [];                    
                this.recipientsItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.recipientsItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]}));
        },
        async searchDocumentAuthor(e) {
            if (e) {
                let selectedIds = this.val.DocumentAuthor.value.length ? this.val.DocumentAuthor.value.map(i => i.id): [];
                this.documentAuthorItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[2], Value: i[1], jobTitle: i[3]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
                }
            else
                this.documentAuthorItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[2], Value: i[1], jobTitle: i[3]}));
        }
    }
}
</script>